import { BuyNowMeasureLaterStyled, CartInfoStyled, DetailsStyled, FaqStyled, InstallationMadeEasyComponent, MeasureConsultationStyled, MeasuringStyled, OverviewStyled, PDPFeaturesInfoOptionalStyled, PDPFeaturesInfoStyled, PDPShadeInfoStyled, ProductFeaturesStyled, ProductMeasuringStyled, ProductSmartStyled, ReviewSliderStyled } from "../overrides/venia-ui/components/ProductFullDetail/productFullDetail.styled";
import ProductFeaturesComponent from "../components/Product/Content/FeaturesComponentPDP";
import ProductSmartComponent from "../components/Product/Content/SmartComponentPDP";
import MeasuringMadeEasyComponent from "../components/Product/Content/MeasureComponentPDP/MeasuringMadeEasy";
import { DesktopReviews } from "../components/Product/Overview/Reviews";
import { getMeasuringContent } from "../utils/product";
import { MeasureExternalPopup } from "../components/Product/MeasurePopup/MeasureExternalPopup";
import BuyNowExternalPopup from "../components/Product/Content/BuyNowPopup/BuyNowExternalPopup";
import InstaWidget from "../components/Product/Yotpo/InstaWidget";
import { PRODUCTS_SKU } from "./constant";
import { YotpoContainerStyled } from "../components/Product/Yotpo/InstaWidget.styled";
var CMS_BLOCKS = {
  WIZARD: {
    INFO: {
      WINDOW_TYPES: 'order-wizard-info-about-windows',
      MOUNT_TYPES: 'order-wizard-info-windows-and-mount-types',
      MEASURING: {
        OUTSIDE: 'order-wizard-info-measuring-and-installing-outside',
        INSIDE: 'order-wizard-info-measuring-and-installing-inside',
        COMMON: 'order-wizard-info-measuring-and-installing-common'
      },
      INSTALLATION: 'installation-popup-order-wizard-help',
      PROFESSIONAL_HELP: 'wizard-pro-install-info',
      MOTORIZED_AND_SMART: 'order-wizard-info-motorised-and-smart',
      FAQS: 'order-wizard-info-faqs'
    },
    WINDOW_TYPE: 'order-wizard-window-type',
    MOUNT_TYPE: {
      INSIDE_AND_OUTSIDE_IMAGE_PREFIX: 'order-wizard-mount-type',
      OUTSIDE_SQUARE_AND_OTHER_IMAGE_PREFIX: 'order-wizard-mount-type-outside-trim',
      INSIDE_TILT_YES_OR_NO_IMAGE_PREFIX: 'order-wizard-mount-type-inside-tilt',
      CHANGE_TO: 'order-wizard-mount-type-change-to-'
    },
    MEASURE: {
      EXPLANATION_VIDEO: {
        INSIDE: 'order-wizard-info-measuring-and-installing-explanation-video-inside',
        OUTSIDE: 'order-wizard-info-measuring-and-installing-explanation-video-outside',
        COMMON: 'order-wizard-info-measuring-and-installing-explanation-video-common'
      }
    },
    CONTROL_TYPE: "order-wizard-control-type",
    ORIENTATION: "order-wizard-orientation",
    MOTORIZED_REMOTE_OPTION_TYPE: "order-wizard-motorized-remote-type",
    MEASURE_RECONFIRMATION: "order-wizard-measure-reconfirmation",
    MOUNT_TYPE_RECONFIRMATION: "order-wizard-mount-type-reconfirmation",
    ORIENTATION_RECONFIRMATION: "order-wizard-orientation-reconfirmation",
    PREFERRED_SHADE_ORIENTATION: 'order-wizard-preferred-shade-orientation',
    MEASURE_GUARANTEE: 'order-wizard-measure-guarantee'
  },
  HEADER: {
    DESKTOP: 'header-navigation-block',
    MOBILE: 'header-mobile-navigation-block'
  },
  FOOTER: {
    HELP_CENTER: 'footer-col-2-help-center',
    ORDER_INFO: 'footer-col-2-order-information',
    SOCIAL_MEDIA: 'footer-col-3-social-media-links',
    PAYMENT_OPTION: 'footer-col-4-payment-options',
    NEED_MORE: 'footer-col-4-need-more-material',
    MOBILE_NAVIGATION: 'footer-mobile-nav-block',
    CONTACT: 'contact-buttons',
    CHECKOUT_FOOTER: 'checkout_footer',
    AMAZON_AND_GOOGLE: 'amazon-and-google-buttons'
  },
  PRODUCT_DETAIL: {
    CONTROL_TYPE: 'product-option-',
    EXPERTISE_FOR: 'expertise-for',
    PDP_MEASURING: 'pdp-measuring',
    PDP_INSTALLATION: 'pdp-installation',
    PDP_INSTALLATION_MADE_EASY: 'installation-made-easy',
    PDP_FREE_SHIPPING_AND_RETURNS: 'pdp-free-shipping-and-returns'
  },
  PRODUCTS: {
    ACCESSORIES: {
      OVERVIEW: {
        FEATURES: 'fabric-replacement-overview-features',
        SPECIFICATIONS: 'fabric-replacement-overview-specifications',
        WARRANTY: 'fabric-replacement-overview-warranty',
        SHIPPING: 'fabric-replacement-overview-shipping',
        REVIEWS: 'fabric-replacement-overview-reviews',
        GALLERY: 'fabric-replacement-overview-gallery',
        DETAILS: 'fabric-replacement-overview-details'
      }
    }
  },
  CART_HELP: {
    MONEY_BACK_GUARANTEE: 'cart-money-back-guarantee',
    FREE_SHIPPING: 'cart-free-shipping',
    TWO_YEAR_WARRANTY: 'cart-two-year-warranty',
    DEDICATED_SUPPORT: 'cart-dedicated-customer-support',
    DELIVERY: 'cart-delivery'
  },
  SHADE_INFO_LIST: {
    BLACKOUT_GUARANTEE: 'shade-info-blackout-guarantee',
    EASY_INSTALL: 'shade-info-easy-install',
    SAVE_ENERGY: 'shade-info-save-energy',
    SHIPS_FROM_DALLAS: 'shade-info-ships-from-dallas'
  }
};
var PRODUCT_DETAIL_HEADER_IDS = {
  FEATURES: 'features',
  SMART: 'smart',
  MEASURING: 'measuring',
  COMPARE: 'compare',
  DETAILS: 'details',
  FAQ: 'faq',
  RATING: 'rating',
  GALLERY: 'gallery'
};
var PRODUCT_DETAIL_HEADERS = {
  BLACKOUT_BLIND: [{
    id: PRODUCT_DETAIL_HEADER_IDS.FEATURES,
    title: 'Features',
    isProductContent: false,
    contentIdentifier: null,
    desktop: {
      offset: -200,
      styledComponent: ProductFeaturesStyled,
      component: ProductFeaturesComponent
    },
    mobile: {
      offset: -200
    },
    order: 1
  }, {
    id: PRODUCT_DETAIL_HEADER_IDS.SMART,
    title: 'Smart',
    isProductContent: false,
    contentIdentifier: null,
    desktop: {
      offset: -100,
      styledComponent: ProductSmartStyled,
      component: ProductSmartComponent
    },
    mobile: {
      offset: -100
    },
    order: 2
  }, {
    id: PRODUCT_DETAIL_HEADER_IDS.GALLERY,
    title: 'Gallery',
    isProductContent: false,
    contentIdentifier: null,
    desktop: {
      offset: -100,
      styledComponent: YotpoContainerStyled,
      component: InstaWidget
    },
    mobile: {
      offset: -50
    },
    order: 3
  }, {
    id: PRODUCT_DETAIL_HEADER_IDS.RATING,
    title: 'Ratings & reviews',
    mobileTitle: 'Reviews',
    isProductContent: false,
    contentIdentifier: null,
    desktop: {
      offset: -100,
      styledComponent: ReviewSliderStyled,
      component: DesktopReviews
    },
    mobile: {
      offset: -50
    },
    order: 4
  }, {
    id: PRODUCT_DETAIL_HEADER_IDS.MEASURING,
    title: 'Measuring & installing',
    isProductContent: false,
    contentIdentifier: null,
    desktop: {
      offset: -220,
      styledComponent: ProductMeasuringStyled,
      component: MeasuringMadeEasyComponent
    },
    mobile: {
      offset: -150
    },
    order: 5
  }, {
    id: PRODUCT_DETAIL_HEADER_IDS.FAQ,
    title: 'FAQs',
    isProductContent: true,
    contentIdentifier: 'faq.html',
    desktop: {
      offset: -50,
      styledComponent: FaqStyled,
      component: null
    },
    mobile: {
      offset: -100
    },
    order: 6
  }, {
    id: PRODUCT_DETAIL_HEADER_IDS.DETAILS,
    title: 'Specifications',
    isProductContent: true,
    contentIdentifier: 'details.html',
    desktop: {
      offset: -50,
      styledComponent: DetailsStyled,
      component: null
    },
    mobile: {
      offset: -100
    },
    order: 7
  }
  // {
  //     id: PRODUCT_DETAIL_HEADER_IDS.COMPARE,
  //     title: 'Compare',
  //     isProductContent: false,
  //     contentIdentifier: null,
  //     desktop: {
  //         offset: -200,
  //         styledComponent: ProductCompareStyled,
  //         component: ProductCompareComponent
  //     },
  //     mobile: {
  //         offset: -100,
  //     },
  //     order: 4
  // },
  ],

  ACCESSORIES: [{
    id: 'features',
    title: 'Feature',
    isProductContent: false,
    contentIdentifier: CMS_BLOCKS.PRODUCTS.ACCESSORIES.OVERVIEW.FEATURES,
    desktop: {
      offset: -173,
      styledComponent: OverviewStyled,
      component: null
    },
    mobile: {
      styledComponent: ReviewSliderStyled,
      component: null
    }
  }, {
    id: 'specifications',
    title: 'Specifications',
    isProductContent: false,
    contentIdentifier: CMS_BLOCKS.PRODUCTS.ACCESSORIES.OVERVIEW.SPECIFICATIONS,
    desktop: {
      offset: -173,
      styledComponent: OverviewStyled,
      component: null
    },
    mobile: {
      styledComponent: OverviewStyled,
      component: null
    }
  }, {
    id: 'warranty',
    title: 'Warranty & Returns',
    isProductContent: false,
    contentIdentifier: CMS_BLOCKS.PRODUCTS.ACCESSORIES.OVERVIEW.WARRANTY,
    desktop: {
      offset: -173,
      styledComponent: OverviewStyled,
      component: null
    },
    mobile: {
      styledComponent: OverviewStyled,
      component: null
    }
  }, {
    id: 'shipping',
    title: 'Shipping',
    isProductContent: false,
    contentIdentifier: CMS_BLOCKS.PRODUCTS.ACCESSORIES.OVERVIEW.SHIPPING,
    desktop: {
      offset: -173,
      styledComponent: OverviewStyled,
      component: null
    },
    mobile: {
      styledComponent: OverviewStyled,
      component: null
    }
  }, {
    id: 'rating',
    title: 'Ratings & reviews',
    isProductContent: false,
    contentIdentifier: CMS_BLOCKS.PRODUCTS.ACCESSORIES.OVERVIEW.REVIEWS,
    desktop: {
      offset: -173,
      styledComponent: OverviewStyled,
      component: null
    },
    mobile: {
      styledComponent: OverviewStyled,
      component: null
    }
  }, {
    id: 'gallery',
    title: 'Gallery',
    isProductContent: false,
    contentIdentifier: CMS_BLOCKS.PRODUCTS.ACCESSORIES.OVERVIEW.GALLERY,
    desktop: {
      offset: -173,
      styledComponent: OverviewStyled,
      component: null
    },
    mobile: {
      styledComponent: OverviewStyled,
      component: null
    }
  }],
  SMART_HUB: [{
    id: 'faq',
    title: 'FAQs',
    isProductContent: true,
    contentIdentifier: 'faq.html',
    desktop: {
      offset: -173,
      styledComponent: FaqStyled,
      component: null
    },
    mobile: {
      styledComponent: FaqStyled,
      component: null
    }
  }],
  REMOTE: [{
    id: 'faq',
    title: 'FAQs',
    isProductContent: true,
    contentIdentifier: 'faq.html',
    desktop: {
      offset: -173,
      styledComponent: FaqStyled,
      component: null
    },
    mobile: {
      styledComponent: FaqStyled,
      component: null
    }
  }],
  MOTORIZATION_UPGRADE: [{
    id: 'faq',
    title: 'FAQs',
    isProductContent: true,
    contentIdentifier: 'faq.html',
    desktop: {
      offset: -173,
      styledComponent: FaqStyled,
      component: null
    },
    mobile: {
      styledComponent: FaqStyled,
      component: null
    }
  }]
};
var PRODUCT_CUSTOMIZE_HEADERS = {
  BLACKOUT_BLIND: [{
    id: 'about',
    title: 'About Windows',
    isProductContent: false,
    dynamicContent: false,
    contentIdentifier: CMS_BLOCKS.WIZARD.INFO.WINDOW_TYPES,
    desktop: {
      styledComponent: OverviewStyled,
      component: null,
      tabOrder: 0
    },
    mobile: {
      styledComponent: OverviewStyled,
      component: null
    }
  }, {
    id: 'mount_types',
    title: 'Mount Types',
    isProductContent: false,
    dynamicContent: false,
    contentIdentifier: CMS_BLOCKS.WIZARD.INFO.MOUNT_TYPES,
    desktop: {
      styledComponent: DetailsStyled,
      component: null,
      tabOrder: 1
    },
    mobile: {
      styledComponent: DetailsStyled,
      component: null
    }
  }, {
    id: 'measuring',
    title: 'Measuring',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: getMeasuringContent,
    desktop: {
      styledComponent: MeasuringStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: MeasuringStyled,
      component: null
    }
  }, {
    id: 'installation',
    title: 'Installation',
    isProductContent: false,
    contentIdentifier: CMS_BLOCKS.WIZARD.INFO.INSTALLATION,
    desktop: {
      styledComponent: FaqStyled,
      component: null,
      tabOrder: 3
    },
    mobile: {
      styledComponent: FaqStyled,
      component: null
    }
  }, {
    id: 'faq',
    title: "FAQs",
    isProductContent: false,
    contentIdentifier: CMS_BLOCKS.WIZARD.INFO.FAQS,
    desktop: {
      styledComponent: ReviewSliderStyled,
      component: null,
      tabOrder: 4
    },
    mobile: {
      styledComponent: ReviewSliderStyled,
      component: null
    }
  }],
  BLACKOUT_MADE_EASY: [{
    id: 'pdp_measuring',
    title: 'How to measure',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.PRODUCT_DETAIL.PDP_MEASURING,
    desktop: {
      styledComponent: MeasuringStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: MeasuringStyled,
      component: null
    }
  }, {
    id: 'pdp_installation',
    title: 'Easy DIY installation',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.PRODUCT_DETAIL.PDP_INSTALLATION,
    desktop: {
      styledComponent: MeasuringStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: MeasuringStyled,
      component: null
    }
  }, {
    id: 'pdp_measure_consultation',
    title: 'UBlockout free consultation and quote',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: null,
    desktop: {
      styledComponent: MeasureConsultationStyled,
      component: MeasureExternalPopup,
      tabOrder: 2
    },
    mobile: {
      styledComponent: MeasureConsultationStyled,
      component: MeasureExternalPopup
    }
  }, {
    id: 'pdp_installation_made_easy',
    title: 'Installation made easy',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.PRODUCT_DETAIL.PDP_INSTALLATION_MADE_EASY,
    desktop: {
      styledComponent: InstallationMadeEasyComponent,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: InstallationMadeEasyComponent,
      component: null
    }
  }, {
    id: 'pdp_buy_now_measure_later',
    title: 'Buy Now, Order Later',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: null,
    desktop: {
      styledComponent: BuyNowMeasureLaterStyled,
      component: BuyNowExternalPopup,
      tabOrder: 2
    },
    mobile: {
      styledComponent: BuyNowMeasureLaterStyled,
      component: BuyNowExternalPopup
    }
  }]
};
var PRODUCT_CUSTOMIZE_HELP_CONFIG = {
  BLACKOUT_BLIND: [{
    id: 'motorised',
    title: 'Motorized & Smart',
    isProductContent: false,
    contentIdentifier: CMS_BLOCKS.WIZARD.INFO.MOTORIZED_AND_SMART,
    desktop: {
      styledComponent: FaqStyled,
      component: null,
      tabOrder: 3
    },
    mobile: {
      styledComponent: FaqStyled,
      component: null
    }
  }, {
    id: 'professional_help',
    title: 'Professional installation',
    isProductContent: false,
    contentIdentifier: CMS_BLOCKS.WIZARD.INFO.PROFESSIONAL_HELP,
    desktop: {
      styledComponent: FaqStyled,
      component: null,
      tabOrder: 3
    },
    mobile: {
      styledComponent: FaqStyled,
      component: null
    }
  }, {
    id: 'preferred_shade_orientation',
    title: 'Preferred shade orientation',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.WIZARD.PREFERRED_SHADE_ORIENTATION,
    desktop: {
      styledComponent: MeasuringStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: MeasuringStyled,
      component: null
    }
  }, {
    id: 'measure_guarantee',
    title: 'Measure Guarantee',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.WIZARD.MEASURE_GUARANTEE,
    desktop: {
      styledComponent: MeasuringStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: MeasuringStyled,
      component: null
    }
  }]
};
var SHOPPING_CART_HELP_CONFIG = {
  CART_INFO: [{
    id: 'cart_money_back_guarantee',
    title: '30-day money back guarantee',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.CART_HELP.MONEY_BACK_GUARANTEE,
    desktop: {
      styledComponent: CartInfoStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: CartInfoStyled,
      component: null
    }
  }, {
    id: 'cart_free_shipping',
    title: 'Free Shipping within Mainland USA',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.CART_HELP.FREE_SHIPPING,
    desktop: {
      styledComponent: CartInfoStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: CartInfoStyled,
      component: null
    }
  }, {
    id: 'cart_two_year_warranty',
    title: '2-year warranty',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.CART_HELP.TWO_YEAR_WARRANTY,
    desktop: {
      styledComponent: CartInfoStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: CartInfoStyled,
      component: null
    }
  }, {
    id: 'cart_dedicated_support',
    title: 'Dedicated customer support',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.CART_HELP.DEDICATED_SUPPORT,
    desktop: {
      styledComponent: CartInfoStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: CartInfoStyled,
      component: null
    }
  }]
};
var SHADES_INFO_LIST_CONFIG = {
  SHADE_INFO_LIST: [{
    id: 'shade_info_blackout_guarantee',
    title: '100% blackout guarantee',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.SHADE_INFO_LIST.BLACKOUT_GUARANTEE,
    desktop: {
      styledComponent: PDPShadeInfoStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: PDPShadeInfoStyled,
      component: null
    }
  }, {
    id: 'shade_info_easy_install',
    title: 'Easy install',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.SHADE_INFO_LIST.EASY_INSTALL,
    desktop: {
      styledComponent: PDPShadeInfoStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: PDPShadeInfoStyled,
      component: null
    }
  }, {
    id: 'shade_info_save_energy',
    title: 'Save energy',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.SHADE_INFO_LIST.SAVE_ENERGY,
    desktop: {
      styledComponent: PDPShadeInfoStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: PDPShadeInfoStyled,
      component: null
    }
  }, {
    id: 'shade_info_ships_from_dallas',
    title: 'Ships from Dallas',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.SHADE_INFO_LIST.SHIPS_FROM_DALLAS,
    desktop: {
      styledComponent: PDPShadeInfoStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: PDPShadeInfoStyled,
      component: null
    }
  }]
};
var PRODUCT_HIGHLIGHTS_LIST_CONFIG = {
  PRODUCT_HIGHLIGHTS_LIST: [{
    id: 'product_highlights_blackout_guarantee',
    title: '100% blackout guarantee',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.SHADE_INFO_LIST.BLACKOUT_GUARANTEE,
    desktop: {
      styledComponent: PDPFeaturesInfoOptionalStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: PDPFeaturesInfoOptionalStyled,
      component: null
    }
  }, {
    id: 'product_highlights_delivery',
    title: 'Delivery',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.CART_HELP.DELIVERY,
    desktop: {
      styledComponent: PDPFeaturesInfoOptionalStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: PDPFeaturesInfoOptionalStyled,
      component: null
    }
  }, {
    id: 'product_highlights_free_shipping',
    title: 'Free USA shipping',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.PRODUCT_DETAIL.PDP_FREE_SHIPPING_AND_RETURNS,
    desktop: {
      styledComponent: PDPFeaturesInfoStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: PDPFeaturesInfoStyled,
      component: null
    }
  }, {
    id: 'product_highlights_love_it',
    title: 'Love it or send it back',
    isProductContent: false,
    dynamicContent: true,
    contentIdentifier: CMS_BLOCKS.CART_HELP.MONEY_BACK_GUARANTEE,
    desktop: {
      styledComponent: PDPFeaturesInfoStyled,
      component: null,
      tabOrder: 2
    },
    mobile: {
      styledComponent: PDPFeaturesInfoStyled,
      component: null
    }
  }]
};
var PRODUCT_DETAILS_SKU_MAP = {
  [PRODUCTS_SKU.SMART_HUB]: PRODUCT_DETAIL_HEADERS.SMART_HUB,
  [PRODUCTS_SKU.REMOTE]: PRODUCT_DETAIL_HEADERS.REMOTE,
  [PRODUCTS_SKU.MOTORIZATION_UPGRADE]: PRODUCT_DETAIL_HEADERS.MOTORIZATION_UPGRADE
};
export { CMS_BLOCKS, PRODUCT_DETAIL_HEADER_IDS, PRODUCT_DETAIL_HEADERS, PRODUCT_CUSTOMIZE_HEADERS, PRODUCT_CUSTOMIZE_HELP_CONFIG, PRODUCT_DETAILS_SKU_MAP, SHOPPING_CART_HELP_CONFIG, SHADES_INFO_LIST_CONFIG, PRODUCT_HIGHLIGHTS_LIST_CONFIG };